/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { LandingPage } from '@composite';

const IndexPage = () => (
  <Layout>
    <StaticSEO pageId='home' />
    <LandingPage />
  </Layout>
);

export default IndexPage;
